import { Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const StyledCloseButtonContainer = styled.i`
  font-size: 1.2rem;
  height: 100%;
  width: 1.3rem;
  color: #12a557;
`;

const StyledIconButton = styled(IconButton)`
  width: 2.2rem;
  height: 2.2rem;
  margin: 3px 2px;
`;

const StylePinButtonContainer = styled.i`
  font-size: 1rem;
  height: 100%;
  width: 1.3rem;
`;

const StyledImg = styled.img`
  width: 8rem;
  height: 2.5rem;
  margin-top: 4px;
  margin-right: 5px;
`;
function SideMenuHeader({ menuOpen, setPinned, setMenuOpen, pinned }) {

  const history = useHistory();

  return (
    <div
      aria-label="sidemenu-header-container"
      className={menuOpen ? "sideMenuHeader" : "sideMenuHeader__Open"}
    >
      {menuOpen ? (
        <>
          <StyledIconButton
            aria-label="sidemenu-close-button"
            onClick={() => setMenuOpen()}
          >
            <StyledCloseButtonContainer className="fas fa-times" />
          </StyledIconButton>

          <StyledImg
            onClick={() => history.push("/")}
            src={process.env.PUBLIC_URL + "/logo.png"}
          />
          <StyledIconButton
            onClick={() => setPinned()}
            aria-label="sidemenu-header-pin-button"
            className="menuIcon"
          >
            {pinned ? (
              <Tooltip
                aria-label="sidemenu-unpin-menu"
                title="Desafixar menu"
                arrow
                placement="right"
              >
                <StylePinButtonContainer
                  style={{ color: "#12a557" }}
                  className="fas fa-map-pin"
                />
              </Tooltip>
            ) : (
              <Tooltip
                aria-label="sidemenu-pin-menu"
                title="Fixar menu"
                arrow
                placement="right"
              >
                <StylePinButtonContainer
                  style={{ color: "#757575", transform: "rotate(-25deg)" }}
                  className="fas fa-map-pin"
                />
              </Tooltip>
            )}
          </StyledIconButton>
        </>
      ) : (
        <Tooltip
          aria-label="sidemenu-open-tooltip"
          title="Menu"
          arrow
          placement="right"
        >
          <IconButton
            aria-label="sidemenu-open-button"
            onClick={() => setMenuOpen()}
            className="menuIcon__Open"
          >
            <MenuIcon />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
}

export default memo(SideMenuHeader);

SideMenuHeader.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  pinned: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
  setPinned: PropTypes.func.isRequired,
};
