import { Select } from "antd";
import styled from "styled-components";

export const InputLabel = styled.span<{ isRequired?: boolean }>`
  font-size: 0.9rem;
  font-weight: 500;
  color: #989898;

  &::after {
    content: ${(props) => (props.isRequired ? '"*"' : '""')};
    color: tomato;
  }
`;

export const ErrorMessage = styled.span`
  color: tomato;
  font-size: 0.8rem;

  &::before {
    content: "*";
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;

  .ant-select {
    .ant-select-selector {
      height: 2.5rem !important;
      align-items: center;
    }
  }
`;

export const StyledSelect = styled(Select)`
  &.error-border {
    .ant-select-selector {
      border: 1px solid red;
    }
  }

  &.info-border {
    .ant-select-selector {
      border: 1px solid #20a45a;
    }
  }

  width: 100%;

  .ant-select-selector {
    height: 2.5rem !important;
    align-items: center;
  }
`;
