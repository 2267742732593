import { Tooltip } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import React, { memo } from "react";
import { URLS } from "res/URLS";
import Can from "shared/components/Can";
import {
  StyledIcon,
  StyledIconOpen,
  StyledLink,
  StyledSidemenuItemContainer,
} from "./SideMenuItems";
import PopoverSidemenu from "./components/PopoverSidemenu";

function SideMenuItemsFooter({ menuOpen, handleActive, activeItem }) {
  const customRef = React.useRef();
  const [anchorEl, setAnchorEl] = React.useState(() => { });
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setAnchorEl(menuOpen.currentTarget);
    setOpen((prev) => !prev);
  };

  return (
    <>
      {menuOpen ? (
        <div
          aria-label="sidemenu-footer-container-open"
          className="sideMenuItemsProfile__Open"
        >
          <Can
            permissionRequired={[
              "relatorios.conferencia-do-patio",
              "relatorios.comparativo-maquinas",
              "relatorios.conferencia-campo",
              "relatorios.padroes-de-hvi",
              "relatorios.estoque",
              "relatorios.take-up.calendario",
            ]}
          >
            {({ hasPermission }) =>
              hasPermission && (
                <StyledSidemenuItemContainer
                  active={
                    activeItem === URLS.MENU_RELATORIOS ? "item-active" : null
                  }
                >
                  <StyledLink to={URLS.MENU_RELATORIOS}>
                    <ListItem
                      onClick={() => handleActive(URLS.MENU_RELATORIOS)}
                      className="sideMenuItemFooter__Open"
                      button
                      aria-label="sidemenu-footer-relatorios-button-open"
                      key="settings-menu-item"
                      active={
                        activeItem === URLS.MENU_RELATORIOS
                          ? "item-active"
                          : null
                      }
                    >
                      <StyledIconOpen
                        style={{ minWidth: 56 }}
                        active={
                          activeItem === URLS.MENU_RELATORIOS
                            ? "item-active"
                            : null
                        }
                      >
                        <i className="icon-chart" aria-hidden="true"></i>
                      </StyledIconOpen>
                      <ListItemText secondary="Relatórios" style={{ marginRight: 32 }} />
                    </ListItem>
                  </StyledLink>
                </StyledSidemenuItemContainer>
              )
            }
          </Can>

          <Can
            permissionRequired={[
              "motivo-parada.web.visualizar",
              "transportador.web.visualizar",
            ]}
          >
            {({ hasPermission }) =>
              hasPermission && (
                <StyledSidemenuItemContainer
                  active={
                    activeItem === URLS.MENU_CADASTROS ? "item-active" : null
                  }
                >
                  <StyledLink to={URLS.MENU_CADASTROS}>
                    <ListItem
                      onClick={() => handleActive(URLS.MENU_CADASTROS)}
                      className="sideMenuItemFooter__Open"
                      button
                      aria-label="sidemenu-footer-cadastros-button-open"
                      key="settings-menu-item"
                      active={
                        activeItem === URLS.MENU_CADASTROS
                          ? "item-active"
                          : null
                      }
                    >
                      <StyledIconOpen
                        style={{ minWidth: 56 }}
                        active={
                          activeItem === URLS.MENU_CADASTROS
                            ? "item-active"
                            : null
                        }
                      >
                        <i
                          className="fas fa-clipboard-list"
                          aria-hidden="true"
                        ></i>
                      </StyledIconOpen>
                      <ListItemText secondary="Cadastros" />
                    </ListItem>
                  </StyledLink>
                </StyledSidemenuItemContainer>
              )
            }
          </Can>

          <Can
            permissionRequired={[
              "parametro-integracao.web.visualizar",
              "configuracoes-maquinas.web.visualizar",
              "email-beneficiamento-diario.web.visualizar",
            ]}
          >
            {({ hasPermission }) =>
              hasPermission && (
                <StyledSidemenuItemContainer
                  active={
                    activeItem === URLS.MENU_CONFIGURACOES
                      ? "item-active"
                      : null
                  }
                >
                  <StyledLink to={URLS.MENU_CONFIGURACOES}>
                    <ListItem
                      onClick={() => handleActive(URLS.MENU_CONFIGURACOES)}
                      className="sideMenuItemFooter__Open"
                      button
                      aria-label="sidemenu-footer-profile-button-open"
                      key="settings-menu-item"
                      active={
                        activeItem === URLS.MENU_CONFIGURACOES
                          ? "item-active"
                          : null
                      }
                    >
                      <StyledIconOpen
                        style={{ minWidth: 56 }}
                        active={
                          activeItem === URLS.MENU_CONFIGURACOES
                            ? "item-active"
                            : null
                        }
                      >
                        <i className="fas fa-cog" aria-hidden="true"></i>
                      </StyledIconOpen>
                      <ListItemText secondary="Configurações" />
                    </ListItem>
                  </StyledLink>
                </StyledSidemenuItemContainer>
              )
            }
          </Can>

          <StyledSidemenuItemContainer
            active={false}
          >
            <StyledLink to={"#"}>
              <ListItem
                onClick={handleClick}
                className="sideMenuItemFooter__Open"
                button
                aria-label="sidemenu-footer-profile-button-open"
                key="account-menu-item"
              >
                <StyledIconOpen
                  style={{ minWidth: 56 }}
                >
                  <i
                    className="fas fa-user-circle"
                    style={{ fontSize: 20 }}
                    aria-hidden="true"
                  ></i>
                </StyledIconOpen>

                <ListItemText secondary="Perfil" />
                <PopoverSidemenu
                  menuOpen={menuOpen}
                  anchorEl={anchorEl}
                  open={open}
                />
              </ListItem>
            </StyledLink>
          </StyledSidemenuItemContainer>
        </div>
      ) : (
        <div className="sideMenuItemsProfile">
          <Can
            permissionRequired={[
              "relatorios.conferencia-do-patio",
              "relatorios.comparativo-maquinas",
              "relatorios.conferencia-campo",
              "relatorios.padroes-de-hvi",
              "relatorios.estoque",
              "relatorios.take-up.calendario",
            ]}
          >
            {({ hasPermission }) =>
              hasPermission && (
                <StyledSidemenuItemContainer
                  active={
                    activeItem === URLS.MENU_RELATORIOS ? "item-active" : null
                  }
                >
                  <StyledLink to={URLS.MENU_RELATORIOS}>
                    <ListItem
                      onClick={() => handleActive(URLS.MENU_RELATORIOS)}
                      className="sideMenuItemFooter"
                      button
                      aria-label="sidemenu-footer-relatorios-button-closed"
                      key="settings-menu-item"
                      active={
                        activeItem === URLS.MENU_RELATORIOS
                          ? "item-active"
                          : null
                      }
                    >
                      <Tooltip title="Relatórios" arrow placement="right">
                        <StyledIcon
                          active={
                            activeItem === URLS.MENU_RELATORIOS
                              ? "item-active"
                              : null
                          }
                        >
                          <i className="icon-chart" aria-hidden="true"></i>
                        </StyledIcon>
                      </Tooltip>
                    </ListItem>
                  </StyledLink>
                </StyledSidemenuItemContainer>
              )
            }
          </Can>

          <Can
            permissionRequired={[
              "motivo-parada.web.visualizar",
              "transportador.web.visualizar",
            ]}
          >
            {({ hasPermission }) =>
              hasPermission && (
                <StyledSidemenuItemContainer
                  active={
                    activeItem === URLS.MENU_CADASTROS ? "item-active" : null
                  }
                >
                  <StyledLink to={URLS.MENU_CADASTROS}>
                    <ListItem
                      onClick={() => handleActive(URLS.MENU_CADASTROS)}
                      className="sideMenuItemFooter"
                      button
                      aria-label="sidemenu-footer-cadastros-button-closed"
                      key="settings-menu-item"
                      active={
                        activeItem === URLS.MENU_CADASTROS
                          ? "item-active"
                          : null
                      }
                    >
                      <Tooltip title="Cadastros" arrow placement="right">
                        <StyledIcon
                          active={
                            activeItem === URLS.MENU_CADASTROS
                              ? "item-active"
                              : null
                          }
                        >
                          <i
                            className="fas fa-clipboard-list"
                            aria-hidden="true"
                          ></i>
                        </StyledIcon>
                      </Tooltip>
                    </ListItem>
                  </StyledLink>
                </StyledSidemenuItemContainer>
              )
            }
          </Can>

          <Can
            permissionRequired={[
              "parametro-integracao.web.visualizar",
              "configuracoes-maquinas.web.visualizar",
              "email-beneficiamento-diario.web.visualizar",
            ]}
          >
            {({ hasPermission }) =>
              hasPermission && (
                <StyledSidemenuItemContainer
                  active={
                    activeItem === URLS.MENU_CONFIGURACOES
                      ? "item-active"
                      : null
                  }
                >
                  <StyledLink to={URLS.MENU_CONFIGURACOES}>
                    <ListItem
                      className="sideMenuItemFooter"
                      button
                      onClick={() => handleActive(URLS.MENU_CONFIGURACOES)}
                      active={
                        activeItem === URLS.MENU_CONFIGURACOES
                          ? "item-active"
                          : null
                      }
                      key="settings-menu-item"
                      aria-label="sidemenu-footer-settings-button-closed"
                    >
                      <Tooltip title="Configurações" arrow placement="right">
                        <StyledIcon
                          active={
                            activeItem === URLS.MENU_CONFIGURACOES
                              ? "item-active"
                              : null
                          }
                        >
                          <i className="fas fa-cog" aria-hidden="true"></i>
                        </StyledIcon>
                      </Tooltip>
                    </ListItem>
                  </StyledLink>
                </StyledSidemenuItemContainer>
              )
            }
          </Can>

          <StyledSidemenuItemContainer
            active={false}
          >
            <ListItem
              className="sideMenuItemFooter"
              button
              onClick={handleClick}
              key="account-menu-item"
              aria-label="sidemenu-footer-profile-button-closed"
            >
              <Tooltip ref={customRef} title="Perfil" arrow placement="right">
                <StyledIcon>
                  <i
                    className="fas fa-user-circle"
                    style={{ color: "rgba(0, 0, 0, 0.54)" }}
                    aria-hidden="true"
                  ></i>
                </StyledIcon>
              </Tooltip>
              <PopoverSidemenu
                menuOpen={menuOpen}
                anchorEl={customRef.current}
                open={open}
              />
            </ListItem>
          </StyledSidemenuItemContainer>
        </div>
      )}
    </>
  );
}

export default memo(SideMenuItemsFooter);

SideMenuItemsFooter.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
};
