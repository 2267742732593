// concentrar aqui todas as paths da aplicação

enum Path {
  EXCLUIR_SESSAO_ROLOS = "/sessoes/:sessaoId/excluir",
  CONFERENCIA_CAMPO = "/conferencia-campo",
  CONFERENCIA_CAMPO_DETALHES = "/conferencia-campo/:fazendaId/mapa/:safraId",
  RELATORIO_PADROES_HVI = "/padroes-hvi",
  COMERCIAL = "/comercial",
  COMERCIAL_DETALHES_CONTRATO = "/comercial/detalhes/:grupoClienteId",
  IMPORTACAO_ACTS = "/cadastro/acts/importacao",
  ESTOQUE_COMERCIAL = "/estoque/comercial",
  ESTOQUE_RELATORIO = "/estoque/relatorio",
  EMAILS_BENEFICIAMENTO_DIARIO = "/emails-beneficiamento-diario",
  CARGA_EM_ANDAMENTO = "/portal-balanca/carga-em-andamento/:fazendaId/:patioId",
  FARDINHOS_NO_PORTO = "/comercial/fardinhos-no-porto",
  BENEFICIAMENTO = "/beneficiamento",
  FARDINHOS_NAO_CLASSIFICADOS = "/fardinhos-nao-classificados",
  ENTRADA_PRODUCAO = "/entrada-producao",
}

export default Path;
