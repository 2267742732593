/*eslint-disable*/
import { MsalAuthProvider, LoginType } from "react-aad-msal";
import { Logger, LogLevel } from "msal";
import { AMAGGI_AZURE_AD_AUTHORITY, AMAGGI_AZURE_AD_CLIENT_ID } from '../envConfig'

export const msalConfig = {
  auth: {
    authority: AMAGGI_AZURE_AD_AUTHORITY,
    clientId: AMAGGI_AZURE_AD_CLIENT_ID,
    redirectUri: `${window.location.origin}`,
    postLogoutRedirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
  system: {
    logger: new Logger(
      (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
      {
        level: LogLevel.Verbose,
        piiLoggingEnabled: false,
      }
    ),
  },
};

export const authenticationParameters = {
  scopes: ["openid"],
};

// Options
export const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: `${window.location.origin}`,
};

export const authProvider = new MsalAuthProvider(
  msalConfig,
  authenticationParameters,
  options
);
