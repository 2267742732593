const {
  REACT_APP_BASE_URL: BASE_URL,
  REACT_APP_AMAGGI_AZURE_AD_AUTHORITY: AMAGGI_AZURE_AD_AUTHORITY,
  REACT_APP_AMAGGI_AZURE_AD_CLIENT_ID: AMAGGI_AZURE_AD_CLIENT_ID,
} = window.env || process.env;

export {
  BASE_URL,
  AMAGGI_AZURE_AD_AUTHORITY,
  AMAGGI_AZURE_AD_CLIENT_ID
}
