import { makeStyles } from "@material-ui/core/styles";

// Classe utilizada para a animação do 'Content' quando o SideMenu estiver Pinnado
const usePinnedFormStyle = makeStyles((theme) => ({
  appBarShift: {
    marginLeft: "10.4rem",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: 0,
    }),
  },
}));

export default usePinnedFormStyle;
