import styled from "styled-components";
import { Typography, Button } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

export const StyledPaper = styled.div`
  width: 480px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 0px 10px #00000033;
`;

export const StyledTypography = styled(Typography)`
  align-self: center;
  padding-bottom: 5px;
`;

export const StyledButton = styled(Button)`
  align-self: flex-end;
  margin: 25px 0px 5px 0px;
  padding: 8px 6px;
  font-weight: 400;
  height: 50px;
  background-color: #12a557;
  text-transform: none;
  width: 100%;
  box-shadow: none;
  &:hover {
    box-shadow: none;
    background-color: #037f3e;
  }

  & .MuiButton-startIcon {
    position: relative;
    left: 0px;
  }

  & .MuiButton-label {
    position: relative;
    justify-content: left;
  }
`;

export const StyledTextField = styled(TextField)({
  "& .MuiFilledInput-root": {
    borderBottom: "none",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
  },
  "& .MuiFilledInput-underline": {
    "&:before": {
      borderBottom: "none ",
    },
    "&:after": {
      borderBottom: "1px solid green",
    },

    "&.Mui-error": {
      "&:before": {
        borderBottom: "none ",
      },
      "&:after": {
        borderBottom: "1px solid red",
      },
      "&.Mui-focused": {
        "&:before": {
          borderBottom: "none ",
        },
        "&:after": {
          borderBottom: "1px solid red",
        },
      },
    },
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#048004",
    "&.Mui-error": {
      color: "red",
    },
  },
});

export const StyledProfileIcon = styled.i`
  font-size: 1rem;
  color: #989898;
`;

export const StyledLoadingButton = styled(Button)`
  align-self: flex-end;
  width: 8rem;
  height: 2.5rem;
  margin: 25px 0px 5px 0px;
  padding: 8px 10px;
  font-weight: 400;
  background-color: #037f3e;
`;

export const StyledCircular = styled(CircularProgress)`
  & .MuiCircularProgress-svg {
    color: white !important;
  }
  margin-left: 10px;
`;
