import Skeleton from "@material-ui/lab/Skeleton";
import { SelectProps } from "antd";
import React from "react";
import { useQuery } from "react-query";
import { URLS } from "res/URLS";
import { findAll } from "services/useQueryApi";
import { StyledSelect } from "./antd/Inputs/styles/CommonStyles";

function SafraSelect(props: SelectProps) {
  const { isLoading, data } = useGetSafras();

  if (isLoading)
    return <Skeleton animation="wave" width={"100px"} height={"3rem"} />;

  return (
    <StyledSelect
      placeholder="Safra"
      defaultValue={data.safraAtual.id}
      options={data.safras.map((safra) => ({
        value: safra?.id,
        label: safra?.ano,
      }))}
      {...props}
    />
  );
}

export type SafrasResponse = {
  id: string;
  dataCriacao: string;
  ano: string;
  dataInicial: string;
  dataFinal: string;
  atual: boolean;
  fibraMinimo: number;
  fibraMaximo: number;
  resistenciaMinimo: number;
  resistenciaMaximo: number;
  micronaireMinimo: number;
  micronaireMaximo: number;
}[];

export async function fetchSafras() {
  const { data } = (await findAll(URLS.SAFRAS)) as { data: SafrasResponse };

  return {
    safras: data,
    safraAtual: data?.find((safra) => safra.atual),
  };
}

export const SAFRAS_QUERY_KEY = ["safras"];
export function useGetSafras() {
  return useQuery(["safras"], fetchSafras, {
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

export default SafraSelect;
