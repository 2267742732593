import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import useGetPermissions from "shared/hooks/useGetPermissions";
import usePinnedFormStyle from "../hooks/usePinnedFormStyle";
import ContentContainer from "./components/ContentContainer";
import SideMenu from "./components/SideMenu";
import ErrorFallback, { onError } from "./ErrorFallback";
import Container from "./LayoutStylesContainer";

export default function Layout({ children }) {
  useGetPermissions();

  const [menuOpen, setMenuOpen] = useState(false);
  const [pinned, setPinned] = useState(false);

  const classes = usePinnedFormStyle();

  function handleCloseMenu() {
    setMenuOpen(!menuOpen);

    if (menuOpen && pinned) {
      setPinned(!pinned);
    }
  }

  function handlePinMenu() {
    setPinned(!pinned);
  }

  return (
    <Container
      aria-label="layout-main-container"
      className={clsx({
        [classes.appBarShift]: pinned,
      })}
    >
      <>
        <div className="sideMenuArea">
          <SideMenu
            menuOpen={menuOpen}
            setMenuOpen={handleCloseMenu}
            pinned={pinned}
            setPinned={handlePinMenu}
            aria-label="layout-main-sidemenu-area"
          />
        </div>
        <ContentContainer pinned={pinned} aria-label="layout-main-content-area">
          <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
            {children}
          </ErrorBoundary>
        </ContentContainer>
      </>
    </Container>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
