import React, { PropsWithChildren } from "react";
import { ModuleAccess } from "../../../../shared/typings/permissions";
import Can from "../../../../shared/components/Can";
import { Skeleton } from "antd";
import styled from "styled-components";

export interface ProtectedMenuItemProps {
  permissionRequired: ModuleAccess | ModuleAccess[];
}

function ProtectedMenuItem(props: PropsWithChildren<ProtectedMenuItemProps>) {
  return (
    <Can permissionRequired={props.permissionRequired}>
      {({ isLoadingPermissions, hasPermission }) => {
        if (isLoadingPermissions) return <StyledSkeleton active />;

        return hasPermission ? props.children : null;
      }}
    </Can>
  );
}

const StyledSkeleton = styled(Skeleton.Input)`
  margin: 1px;
  height: 2.9rem;
`;

export default ProtectedMenuItem;
