import React from "react";
import styled from "styled-components";

const StyledIcon = styled.div`
  position: relative;
  width: 46px;
  height: 46px;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  left: 0;

  #ball {
    position: relative;
    background-color: #ffbb32;
    top: 3px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin-right: 1px;
  }

  #firstBar {
    background-color: #009742;
    width: 6px;
    height: 15px;
    margin-right: 2px;
    transform: skewX(35deg);
  }

  #secondBar {
    background-color: #009742;
    width: 6px;
    height: 15px;
    margin-right: 2px;
    transform: skewX(35deg);
  }

  #thirdBar {
    background-color: #009742;
    width: 6px;
    height: 15px;
    transform: skewX(35deg);
    margin-right: 4px;
  }
`;

export default function CustomIcon() {
  return (
    <StyledIcon>
      <div id="ball" />
      <div id="firstBar" />
      <div id="secondBar" />
      <div id="thirdBar" />
    </StyledIcon>
  );
}
