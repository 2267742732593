import { ModuleAccess } from "../typings/permissions";
import usePermissions from "./usePermissions";

function useHasPermission(permission: ModuleAccess | ModuleAccess[]): boolean {
  const { permissions } = usePermissions();

  if (Array.isArray(permission))
    return permissions.some((p) => permission.includes(p));

  return permissions.includes(permission);
}

export default useHasPermission;
