import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { ModuleAccess } from "../../shared/typings/permissions";
import useHasPermission from "../../shared/hooks/useHasPermission";

export interface PrivateRouteProps extends RouteProps {
  permissionRequired: ModuleAccess | ModuleAccess[];
}

function PrivateRoute({
  permissionRequired,
  component,
  ...rest
}: PrivateRouteProps) {
  const hasPermission = useHasPermission(permissionRequired);

  return <Route {...rest} component={hasPermission ? component : GoHome} />;
}

const GoHome = () => <Redirect to="/" />;

export default PrivateRoute;
