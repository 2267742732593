import React from "react";
import { Popover, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import PropTypes from "prop-types";
import { authProvider } from "@infra/msal/authProvider";

const StyledProfilePopover = withStyles({
  root: {
    maxWidth: "18rem",
    maxHeight: "20rem",
    marginLeft: "4rem",
  },
  paper: {
    border: "1px solid rgba(153,153,153,0.2)",
    boxShadow: "8px 8px 12px -6px rgba(153,153,153,1)",
  },
})(Popover);

const StyledProfilePopoverOpen = withStyles({
  root: {
    maxWidth: "20rem",
    maxHeight: "20rem",
    marginLeft: "10rem",
  },
  paper: {
    border: "1px solid rgba(153,153,153,0.2)",
    boxShadow: "8px 8px 12px 1px rgba(153,153,153,1)",
  },
})(Popover);

const StyledPopoverDiv = styled.div`
  overflow-y: hidden;
  padding: 0.5rem;
  color: #989898;
`;

const StyledPopoverButton = styled(Button)`
  font-weight: 400;
  color: black;
`;

export default function PopoverSidemenu({ open, anchorEl, menuOpen }) {
  return (
    <>
      {menuOpen ? (
        <StyledProfilePopoverOpen
          disableScrollLock
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          open={open}
        >
          <StyledPopoverDiv aria-label="perfil-menuopen-popover-area">
            <div style={{ fontWeight: "700", marginLeft: "15px" }}>Olá,</div>
            <div
              style={{ marginLeft: "15px", marginBottom: "15px" }}
              aria-label="perfil-popover-text"
            >
              Aqui você tem algumas configurações da sua conta
            </div>
            <StyledPopoverButton
              aria-label="perfil-logout-button"
              onClick={authProvider.logout}
            >
              Sair
            </StyledPopoverButton>
          </StyledPopoverDiv>
        </StyledProfilePopoverOpen>
      ) : (
        <StyledProfilePopover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "center",
            horizontal: "left",
          }}
          open={open}
          disableScrollLock
        >
          <StyledPopoverDiv aria-label="perfil-menuclosed-popover-area">
            <div style={{ fontWeight: "700", marginLeft: "15px" }}>Olá,</div>
            <div
              style={{ marginLeft: "15px", marginBottom: "15px" }}
              aria-label="perfil-popover-text"
            >
              Aqui você tem algumas configurações da sua conta
            </div>
            <StyledPopoverButton
              aria-label="perfil-logout-button"
              onClick={authProvider.logout}
            >
              Sair
            </StyledPopoverButton>
          </StyledPopoverDiv>
        </StyledProfilePopover>
      )}
    </>
  );
}

PopoverSidemenu.propTypes = {
  open: PropTypes.bool.isRequired,
  menuOpen: PropTypes.bool.isRequired,
};

PopoverSidemenu.defaultProps = {
  anchorEl: () => {},
};
