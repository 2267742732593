/**
 * Caso o usuario so tenha acesso a uma fazenda
 * ele deve ser redirecionado para a pagina de detalhes
 */

import React from "react";
import {
  FAZENDAS_PERMITIDAS_QUERY_KEY,
  fetchFazendas,
  PatioResponse,
} from "shared/components/antd/Inputs/FazendasPermitadasSelect";
import { queryClient } from "shared/components/QueryClientProvider";
import { fetchSafras, SAFRAS_QUERY_KEY } from "shared/components/SafraSelect";

export const pageSwitch = React.lazy(async () => {
  // se nao tiver safras carregadas previamente
  // fazemos a req e cacheamos :D
  if (!queryClient.getQueryData(SAFRAS_QUERY_KEY)) {
    const safras = await fetchSafras();
    queryClient.setQueryData(SAFRAS_QUERY_KEY, safras);
  }

  const fazendaPreviouslyLoaded = queryClient.getQueryData<PatioResponse>(
    FAZENDAS_PERMITIDAS_QUERY_KEY
  );
  if (fazendaPreviouslyLoaded?.length === 1) {
    return import("./RedirectToDetalhes");
  }

  // mais um cache
  // estamos fazendo isso para que qdo o usuario entre na tela
  // ja tenha os dados carregados tornando a UX mais fluida
  const fazendasPermitidas = await fetchFazendas();
  queryClient.setQueryData(FAZENDAS_PERMITIDAS_QUERY_KEY, fazendasPermitidas);

  if (fazendasPermitidas.length === 1) {
    return import("./RedirectToDetalhes");
  }

  return import(".");
});
