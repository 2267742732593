import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = "15rem";
const useDrawerStyle = makeStyles((theme) => ({
  drawerContainer: {
    overflow: "hidden",
    zIndex: 100,
  },
  // close animation
  drawerClose: {
    zIndex: 99,
    overflowX: "hidden",
    width: "4rem",
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.linear,
    }),
  },
  // open animation
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.linear,
    }),
  },
  pinned: {
    boxShadow: "4px 0px 16px -1px rgba(194,194,194,1)",
    zIndex: 10,
  },
}));

export default useDrawerStyle;
