import styled from "styled-components";

const LoaderStyles = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;

  .spinner {
    display: flex;
    margin: 100px auto 0;
    width: 90px;
    text-align: center;
  }

  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: rgb(42, 126, 45);
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.22s;
    animation-delay: -0.22s;
  }

  .spinner .bounce3 {
    -webkit-animation-delay: -0.14s;
    animation-delay: -0.14s;
  }

  .spinner .bounce4 {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
    }
  }

  @keyframes sk-bouncedelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;

export default LoaderStyles;
