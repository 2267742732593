import { datadogLogs } from "@datadog/browser-logs";
import { __DEV__ } from "shared/utils/env";

export function log(
  type: "warn" | "info" | "error",
  message: string,
  messageContext?: object | undefined
) {
  if (__DEV__) return;

  switch (type) {
    case "error":
      datadogLogs.logger.error(message, messageContext);
      return;
    case "info":
      datadogLogs.logger.info(message, messageContext);
      return;
    case "warn":
      datadogLogs.logger.warn(message, messageContext);
      return;
  }
}
