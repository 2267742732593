import { SelectProps } from "antd";
import React, { ReactElement } from "react";
import { useQuery } from "react-query";
import { findAll } from "services/useQueryApi";
import { InputLabel, StyledSelect } from "./styles/CommonStyles";

interface FazendasPermitidasSelect extends SelectProps {
  label?: string | ReactElement;
  noValidateMessage?: boolean;
  useCodigo?: boolean;
}

function FazendasPermitidasSelectContainer({
  label,
  noValidateMessage,
  useCodigo,
  ...props
}: FazendasPermitidasSelect) {
  const { data } = useFazendasPermitidas();

  return (
    <>
      {label && <InputLabel>{label}</InputLabel>}
      <FazendasPermitidasSelect useCodigo={useCodigo} {...props} />

      {!props.value && data?.length && noValidateMessage === false ? (
        <span style={{ color: "#20a45a", display: "block", marginLeft: 5 }}>
          Selecione uma fazenda
        </span>
      ) : null}
    </>
  );
}

interface FazendasPermitidasSelectProps extends SelectProps {
  useCodigo?: boolean;
}

export function FazendasPermitidasSelect(props: FazendasPermitidasSelectProps) {
  const { isLoading, data } = useFazendasPermitidas();

  return (
    <StyledSelect
      loading={isLoading}
      options={
        data?.map((patio) => ({
          value: props.useCodigo ? patio.codigo : patio.id,
          label: patio.descricao,
        })) ?? []
      }
      {...props}
    />
  );
}

export const FAZENDAS_PERMITIDAS_QUERY_KEY = ["fazendasPermitidas"];

export type PatioResponse = {
  id: string;
  descricao: string;
  codigo: string;
  readerName: string;
}[];

export const fetchFazendas = async () => {
  const result = await findAll(`/fazendas/permitidas`);

  if (result === undefined) throw Error("Not found");

  return result.data;
};

export function useFazendasPermitidas(config?: {
  onSuccess: (data: PatioResponse) => void;
}) {
  return useQuery<PatioResponse>(FAZENDAS_PERMITIDAS_QUERY_KEY, fetchFazendas, {
    ...config,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
}

export default FazendasPermitidasSelectContainer;
