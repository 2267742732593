import NiceModal from "@ebay/nice-modal-react";
import React from "react";
import ReactDOM from "react-dom";
import { SnackbarProvider } from "notistack"
import MomentUtils from "@date-io/moment";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ptBR } from "@material-ui/core/locale";
import {
  ThemeProvider,
  createTheme as createMuiTheme,
} from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/pt-br";
import { BrowserRouter } from "react-router-dom";
import { RecoilRoot } from "recoil";
import App from "./infra/routes";

import { ConfigProvider } from "antd";
import ptBr from "antd/es/locale/pt_BR";
import { ReactQueryDevtools } from "react-query/devtools";
import QueryClientProvider from "shared/components/QueryClientProvider";
import { __DEV__ } from "shared/utils/env";
import * as serviceWorker from "./serviceWorker";
// Styles ...
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./index.css";

// Styles ...
import "antd/dist/antd.css"; // or 'antd/dist/antd.less'
import "./fontello/css/fontello.css";
import "./index.css";

import { LoadingProvider } from "contexts/LoadingContext";
import "services/initLogger";

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: "#048004" },
      secondary: { main: "#065E9E" },
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 960,
        md: 1026,
        lg: 1282,
        xl: 1920,
      },
    },
    overrides: {
      MuiButton: {
        root: {
          marginLeft: "0.75rem",
          height: "2.5rem",
        },
        containedPrimary: {
          marginLeft: "0.75rem",
          height: "2.5rem",
        },
      },
    },
  },
  ptBR
);
moment.locale("pt-Br");

ReactDOM.render(
  <QueryClientProvider>
    <LoadingProvider>
      <SnackbarProvider>
        <NiceModal.Provider>
          <RecoilRoot>
            <BrowserRouter>
              <CssBaseline>
                <ConfigProvider locale={ptBr}>
                  <ThemeProvider theme={theme}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      {__DEV__ && (
                        <ReactQueryDevtools
                          initialIsOpen={false}
                          position="bottom-right"
                        />
                      )}
                      <App />
                    </MuiPickersUtilsProvider>
                  </ThemeProvider>
                </ConfigProvider>
              </CssBaseline>
            </BrowserRouter>
          </RecoilRoot>
        </NiceModal.Provider>
      </SnackbarProvider>
    </LoadingProvider>
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
