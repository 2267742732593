import React, { createContext } from "react";
import useLoading from "shared/hooks/useLoading";

interface AuthProviderProps {
  children: JSX.Element;
}

const LoadingContext = createContext({
  loading: false,
  setLoading: (loading: boolean) => { }
});

function LoadingProvider({ children }: AuthProviderProps) {
  
  const {
    loading,
    setLoading
  } = useLoading();

  return (
    <LoadingContext.Provider
      value={{
        loading,
        setLoading
      }}
    >
      {children}
    </LoadingContext.Provider>
  )
}

export { LoadingContext, LoadingProvider };