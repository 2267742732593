import React from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { authProvider } from '@infra/msal/authProvider';
import { StyledPaper, StyledButton } from './LoginPageStyles';
import CustomIcon from './CustomIcon';
import TrailcottonLogo from '../../res/img/logo.svg';

const Box = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLogo = styled.img`
  width: 75%;
`;

const StyledGreet = styled.span`
  font-size: 1.6rem;
  font-weight: lighter;
  color: green;
`;

const StyledText = styled.span`
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0.05px;
  color: #626262;
`;

export default function LoginPage() {
  return (
    <Box>
      <StyledPaper aria-label="login-area">
        <Grid
          style={{ padding: '34px' }}
          aria-label="login-area-container"
          container
          spacing={1}
          alignItems="center"
        >
          <Grid style={{ textAlign: 'center' }} aria-label="login-area-title-grid" item xs={12}>
            <StyledLogo aria-label="trailcotton-logo" src={TrailcottonLogo} />
          </Grid>
          <Grid item xs={12}>
            <StyledGreet>Olá :)</StyledGreet>
          </Grid>
          <Grid item xs={12}>
            <StyledText>Realize seu login para continuar o acesso ao TrailCotton</StyledText>
          </Grid>

          <Grid aria-label="login-area-button-grid" item xs container>
            <StyledButton
              onClick={authProvider.login}
              variant="contained"
              color="primary"
              aria-label="login-area-button"
              startIcon={<CustomIcon />}
            >
              <p style={{ margin: '0 auto', fontSize: '22px' }}>Acessar</p>
            </StyledButton>
          </Grid>
        </Grid>
      </StyledPaper>
    </Box>
  );
}
