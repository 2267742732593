import React, { memo } from "react";
import Container from "@material-ui/core/Container";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledContainer = styled(Container)`
  display: flex;
  flex-grow: 1;
  background-color: #f6f6f6;
  height: 100vh;
  padding: 0;
  width: calc(100% - 150px);
`;

function ContentContainer({ children }) {
  return <StyledContainer maxWidth={false}>{children}</StyledContainer>;
}

export default memo(ContentContainer);

ContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
  pinned: PropTypes.bool.isRequired,
};
