import React from "react";
import { ModuleAccess } from "../typings/permissions";
import useHasPermission from "../hooks/useHasPermission";
import usePermissions from "../hooks/usePermissions";

export interface CanRenderChildrenProps {
  hasPermission: boolean;
  isLoadingPermissions?: boolean;
}

export type CanRenderChildren = (
  props: CanRenderChildrenProps
) => React.ReactNode;

export interface CanProps {
  permissionRequired: ModuleAccess | ModuleAccess[];
  children: CanRenderChildren | React.ReactNode;
}

function Can(props: CanProps) {
  const { isLoadingPermissions } = usePermissions();
  const hasPermission = useHasPermission(props.permissionRequired);

  if (typeof props.children === "function") {
    return props.children({ hasPermission, isLoadingPermissions });
  }

  return hasPermission ? props.children : null;
}

export default Can;
