/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import { ClickAwayListener, Drawer, List } from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { memo, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import useDrawerStyle from '../../../hooks/useDrawerStyle';
import SideMenuHeader from './SideMenuHeader';
import SideMenuItems from './SideMenuItems';
import SideMenuItemsFooter from './SideMenuItemsFooter';
import SideMenuStyles from './SideMenuStyles';

// Usando withStyles para injetar CSS nos componentes do Material
function SideMenu({ menuOpen, setMenuOpen, setPinned, pinned }) {
  const classes = useDrawerStyle();

  const [activeItem, setActiveItem] = useState('/');

  const history = useHistory();

  useEffect(() => {
    if (history.location.pathname !== '/login') setActiveItem(history.location.pathname);
  }, [history.location.pathname]);

  return (
    <SideMenuStyles>
      <ClickAwayListener onClickAway={() => !pinned && menuOpen && setMenuOpen(false)}>
        <Drawer
          aria-label="sidemenu-drawer"
          variant="permanent"
          anchor="left"
          open={menuOpen}
          style={{ width: '73px' }}
          onClose={() => setMenuOpen(false)}
          classes={{
            paper: clsx(classes.drawerContainer, {
              [classes.drawerOpen]: menuOpen,
              [classes.drawerClose]: !menuOpen,
              [classes.pinned]: !pinned && menuOpen,
            }),
          }}
        >
          <List aria-label="sidemenu-content" className="sideMenu">
            <SideMenuHeader
              aria-label="sidemenu-header-area"
              menuOpen={menuOpen}
              pinned={pinned}
              setPinned={setPinned}
              setMenuOpen={setMenuOpen}
            />

            <SideMenuItems
              aria-label={`sidemenu-items-area-${menuOpen ? 'open' : 'closed'}`}
              menuOpen={menuOpen}
              activeItem={activeItem}
              handleActive={setActiveItem}
            />

            <SideMenuItemsFooter
              aria-label={`sidemenu-footer-area-${menuOpen ? 'open' : 'closed'}`}
              menuOpen={menuOpen}
              activeItem={activeItem}
              handleActive={setActiveItem}
            />
          </List>
        </Drawer>
      </ClickAwayListener>
    </SideMenuStyles>
  );
}

export default memo(SideMenu);

SideMenu.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  pinned: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
  setPinned: PropTypes.func.isRequired,
};
