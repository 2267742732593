/*eslint-disable*/
import { BASE_URL } from "@infra/envConfig.js";
import {
  authenticationParameters,
  authProvider,
} from "@infra/msal/authProvider";
import axios from "axios";
import path from "path";
import qs from "query-string";
import * as logger from "services/logger";
import { __TEST__ } from "../shared/utils/env";

const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json;charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  },
});

api.interceptors.request.use(
  async (config) => {
    const { Authorization } = await getToken();
    config.headers = { Authorization };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => response,
  async function (error) {
    const { Authorization } = await getToken();
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      axios.defaults.headers.common["Authorization"] = Authorization;
      return api(originalRequest);
    }

    return Promise.reject(error);
  }
);

if (__TEST__) {
  api.defaults.adapter = require("axios/lib/adapters/http");
}

function expired(exp) {
  if (Date.now() >= exp * 1000) {
    return true;
  }

  return false;
}

async function getToken() {
  const { account, jwtIdToken } = (await authProvider.getAccountInfo()) || {};
  let rawIdToken;

  if (account) {
    const { idToken } = account;
    const { exp } = idToken;

    rawIdToken = jwtIdToken;

    if (expired(exp)) {
      const silentResponse = await authProvider.acquireTokenSilent(
        authenticationParameters
      );
      rawIdToken = silentResponse.idToken.rawIdToken;
    }
  }

  return {
    idToken: rawIdToken,
    Authorization: `Bearer ${rawIdToken}`,
    user_email: account?.userName,
  };
}

async function findAll(
  URL,
  queryParams = {},
  paramsSerializer = undefined,
  version = "v1.0"
) {
  if (!__TEST__) URL = path.join(version, URL);

  const reqEndpoint = `${URL}/${qs.stringify(queryParams)}`;

  try {
    const cancelToken = axios.CancelToken.source();
    const { Authorization, user_email } = await getToken();
    logger.log("info", `start request GET ${reqEndpoint}`, { user_email });

    const { data: response } = await api.get(URL, {
      params: queryParams,
      cancelToken: cancelToken.token,
      headers: { Authorization },
      paramsSerializer,
    });

    logger.log("info", `finish request GET ${reqEndpoint}`, { user_email });

    return { response: response || {}, data: response?.data };
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log("request cancelled:", e.message);
    }

    logger.log("error", `GET ${reqEndpoint}`, {
      error: e,
    });
  }
}

async function findOne(URL, id, version = "v1.0") {
  if (!__TEST__) URL = path.join(version, URL);

  try {
    const { Authorization, user_email } = await getToken();

    logger.log("info", `start request GET ${URL}/${id}`, { user_email });

    const { data: response } = await api.get(`${URL}/${id}`, {
      headers: { Authorization },
    });

    logger.log("info", `finish request GET ${URL}/${id}`, { user_email });

    return response?.data;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log("request cancelled:", e.message);
    } else {
      console.log("another error happened:", e.message);
    }

    logger.log("error", `GET ${URL}/${id}`, {
      error: e,
    });
  }
}

async function save(URL, body, headers = {}, version = "v1.0") {
  if (!__TEST__) URL = path.join(version, URL);

  try {
    const { Authorization, user_email } = await getToken();
    logger.log("info", `start request POST ${URL}`, { user_email });

    const result = await api.post(`${URL}`, body, {
      headers: { Authorization, ...headers },
    });

    logger.log("info", `finish request POST ${URL}`, { user_email });

    return result;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log("request cancelled:", e.message);
    } else {
      console.log("another error happened:", e.message);
    }

    logger.log("error", `POST ${URL}`, {
      error: e,
      body,
    });

    throw e;
  }
}

async function update(URL, body, version = "v1.0") {
  if (!__TEST__) URL = path.join(version, URL);

  try {
    const { Authorization, user_email } = await getToken();

    logger.log("info", `start request PUT ${URL}`, { user_email });

    const result = await api.put(`${URL}`, body, {
      headers: { Authorization },
    });

    logger.log("info", `finish request PUT ${URL}`, { user_email });

    return result;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log("request cancelled:", e.message);
    } else {
      console.log("another error happened:", e.message);
    }

    logger.log("error", `PUT ${URL}`, {
      error: e,
      body,
    });

    throw e;
  }
}

async function patch(URL, body, version = "v1.0") {
  if (!__TEST__) URL = path.join(version, URL);

  try {
    const { Authorization, user_email } = await getToken();

    logger.log("info", `start request PATCH ${URL}`, { user_email });

    const result = await api.patch(`${URL}`, body, {
      headers: { Authorization },
    });

    logger.log("info", `finish request PATCH ${URL}`, { user_email });

    return result;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log("request cancelled:", e.message);
    } else {
      console.log("another error happened:", e.message);
    }

    logger.log("error", `PATCH ${URL}`, {
      error: e,
      body,
    });

    throw e;
  }
}

async function remove(URL, data, version = "v1.0") {
  if (!__TEST__) URL = path.join(version, URL);

  try {
    const { Authorization, user_email } = await getToken();

    logger.log("info", `start request DELETE ${URL}`, { user_email });

    const result = await api.delete(`${URL}`, {
      headers: { Authorization },
      data,
    });

    logger.log("info", `finish request DELETE ${URL}`, { user_email });

    return result;
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log("request cancelled:", e.message);
    } else {
      console.log("another error happened:", e.message);
    }

    logger.log("error", `DELETE ${URL}`, {
      error: e,
      data,
    });
    throw e;
  }
}

async function getBlob(
  URL,
  queryParams = {},
  paramsSerializer = undefined,
  version = "v1.0",
  setDownloadProgress = undefined
) {
  if (!__TEST__) URL = path.join(version, URL);
  
  const reqEndpoint = `${URL}/${qs.stringify(queryParams)}`;

  try {
    const cancelToken = axios.CancelToken.source();
    const { Authorization, user_email } = await getToken();
    logger.log("info", `start request GET ${reqEndpoint}`, { user_email });

    const { data: response } = await api.get(URL, {
      params: queryParams,
      cancelToken: cancelToken.token,
      headers: { Authorization },
      paramsSerializer,
      responseType: "blob",
      onDownloadProgress: progressEvent => {
        if(setDownloadProgress)
          setDownloadProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total))
      }
    });

    logger.log("info", `finish request GET ${reqEndpoint}`, { user_email });

    return { response: response || {}, data: response?.data };
  } catch (e) {
    if (axios.isCancel(e)) {
      console.log("request cancelled:", e.message);
    }

    logger.log("error", `GET ${reqEndpoint}`, {
      error: e,
    });
  }
}

export {
  findAll,
  findOne,
  save,
  update,
  remove,
  api,
  getToken,
  patch,
  getBlob,
};
