import React from "react";
import LoaderStyles from "./LoaderStyles";

export default function Loader() {
  React.useEffect(() => {
    const handleBeforeunload = () => {
      localStorage.clear();
    };

    window.addEventListener("beforeunload", handleBeforeunload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeunload);
    };
  }, []);

  return (
    <LoaderStyles>
      <div className="spinner">
        <div className="bounce1"></div>
        <div className="bounce2"></div>
        <div className="bounce3"></div>
        <div className="bounce4"></div>
        <div className="bounce5"></div>
      </div>
    </LoaderStyles>
  );
}
