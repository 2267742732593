import styled from "styled-components";

const SideMenuStyles = styled.div`
  --sideMenuSize: 15rem;

  .sideMenuContainer {
    height: 100vh;
  }

  .menuIcon {
    &__Open {
      color: #12a557 !important;
    }
    color: #12a557 !important;
  }

  .sideMenu {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .sideMenuHeader {
    &__Open {
      min-height: 5%;
      display: flex;
      justify-content: center;
      margin: 0rem 0.62rem 0rem 0rem;
      padding-right: 0.3125rem;
      padding-bottom: 0.3125rem;
      width: 100%;
    }
    min-height: 5%;
    display: flex;
    justify-content: space-between;
    margin: 0rem 0.62rem 0rem 0.3rem;
    padding-right: 0.7rem;
    padding-bottom: 0.3125rem;
    width: var(--sideMenuSize);
  }

  .sideMenuItems {
    &__Open {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1;
      margin-left: 2px;
      background-color: #fafafa;
    }

    display: flex;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    justify-content: center;
    width: 100%;
  }

  .sideMenuItemContainer {
    display: flex;
    margin: 5px 0px;
  }

  .sideMenuItem {
    &__Open {
      justify-content: center !important;
      padding-left: 23px !important;
      height: 100%;
      &:hover {
        background-color: rgba(0, 0, 0, 0) !important;
      }
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    height: 100%;
    justify-content: center !important;
  }

  .sideMenuItemFooter {
    &__Open {
      padding-left: 0px !important;
      height: 3rem;
      margin-bottom: 5px !important;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04) !important;
    }
    height: 3rem;
    margin-bottom: 5px !important;
    justify-content: center !important;
  }

  .sideMenuItemsProfile {
    &__Open {
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      min-height: 14vh;
      width: 100%;
      padding-top: 4px;
    }
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    min-height: 14vh;
    width: 100%;
  }
`;

export default SideMenuStyles;
