import { useEffect } from "react";
import { useQuery } from "react-query";
import { URLS } from "../../res/URLS";
import { findAll } from "../../services/useQueryApi";
import usePermissions from "./usePermissions";

function useGetPermissions() {
  const { setPermissions, setLoadingPermissions } = usePermissions();
  const { isLoading } = useQuery(
    URLS.USER_INFO,
    async () => {
      const response = await findAll(URLS.USER_INFO);

      return response?.data;
    },
    {
      onSuccess(data) {
        setPermissions(data?.permissoes ?? []);
      },
      retry: 5,
    }
  );

  useEffect(() => {
    setLoadingPermissions(isLoading);
  }, [isLoading, setLoadingPermissions]);
}

export default useGetPermissions;
