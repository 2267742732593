import styled from "styled-components";

const Container = styled.div`
  display: flex;

  .sideMenuArea {
    width: 73px;

    @media (max-width: 960px) {
      width: 63px;
    }
  }
`;

export default Container;
