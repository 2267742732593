/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable react/prop-types */
import { Tooltip } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import sidemenuRoutes from 'infra/routes/SidemenuRoutes';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ProtectedMenuItem from './ProtectedMenuItem';

export const StyledLink = styled(Link)`
  color: grey !important;
  height: 100%;
  width: 100%;
  text-decoration: none;
`;

export const StyledIcon = styled.span`
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: 0;
  color: ${(props) => (props.active ? '#12A557' : null)};

  i {
    font-size: 23px;

    &:before {
      width: 1.5em;
    }
  }
`;

export const StyledIconOpen = styled.span`
  justify-content: center;
  align-items: center;
  padding: 0;
  color: ${(props) => (props.active ? '#12A557' : null)};
  width: 30px;
  display: flex;
  width: 1em;
  height: 1em;

  i {
    font-size: 23px;

    &:before {
      width: 1.5em;
    }
  }
`;

export const StyledSidemenuItemContainer = styled.div`
  display: flex;
  margin: 5px 0px;
  height: 2.9rem;
  box-shadow: ${(props) =>
    props.active === 'item-active' ? '-10px 0px 10px 1px rgba(112, 102, 102, 0.3) !important' : ''};
  &:hover {
    background-color: rgba(0, 0, 0, 0.04) !important;
  }
`;

const StyledScroll = styled.div`
  overflow-y: scroll;

  ::-webkit-scrollbar {
    width: 1px;
  }
`;

function SideMenuItems({ menuOpen, handleActive, activeItem }) {
  return (
    <>
      {menuOpen ? (
        <div aria-label="sidemenu-items-container" className="sideMenuItems__Open">
          <StyledScroll>
            {sidemenuRoutes.map((item) => (
              <ProtectedMenuItem permissionRequired={item.permissaoAcesso} key={item.id}>
                <StyledSidemenuItemContainer
                  aria-label="sidemenu-items-item-container-open"
                  active={activeItem === item.url ? 'item-active' : null}
                >
                  <StyledLink onClick={() => handleActive(item.url)} to={item.url}>
                    <ListItem
                      aria-label={`sidemenu-items-item-${item.text}-open`}
                      className="sideMenuItem__Open"
                      button
                      key={item.id}
                    >
                      <ListItemIcon>
                        <StyledIconOpen
                          aria-label="sidemenu-items-item-icon-open"
                          active={activeItem === item.url}
                        >
                          <i className={item.icon} aria-label={`sidemenu-item-${item.text}-icon`} />
                        </StyledIconOpen>
                      </ListItemIcon>
                      <ListItemText
                        secondary={item.text}
                        aria-label={`sidemenu-item-${item.text}-text`}
                      />
                    </ListItem>
                  </StyledLink>
                </StyledSidemenuItemContainer>
              </ProtectedMenuItem>
            ))}
          </StyledScroll>
        </div>
      ) : (
        <div className="sideMenuItems">
          <StyledScroll>
            {sidemenuRoutes.map((item) => (
              <ProtectedMenuItem
                permissionRequired={item.permissaoAcesso}
                key={item.permissaoAcesso}
              >
                <StyledSidemenuItemContainer
                  aria-label="sidemenu-items-item-container-close"
                  active={activeItem === item.url ? 'item-active' : null}
                  key={item.id}
                >
                  <StyledLink onClick={() => handleActive(item.url)} to={item.url}>
                    <ListItem
                      aria-label="sidemenu-items-item-close"
                      className="sideMenuItem"
                      button
                      key={item.id}
                    >
                      <ListItemIcon>
                        <Tooltip arrow title={item.text} placement="right">
                          <StyledIcon
                            aria-label="sidemenu-items-item-icon-close"
                            active={activeItem === item.url}
                          >
                            <i aria-label={`icon-closed-${item.id}`} className={item.icon} />
                          </StyledIcon>
                        </Tooltip>
                      </ListItemIcon>
                    </ListItem>
                  </StyledLink>
                </StyledSidemenuItemContainer>
              </ProtectedMenuItem>
            ))}
          </StyledScroll>
        </div>
      )}
    </>
  );
}
export default memo(SideMenuItems);

SideMenuItems.propTypes = {
  // favs: PropTypes.arrayOf(PropTypes.string).isRequired,
  // handleFavs: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
};
