// @ts-nocheck

import create from "zustand";
import { ModuleAccess } from "../typings/permissions";
import { persist } from "zustand/middleware";

export type PermissionsState = {
  permissions: ModuleAccess[];
  isLoadingPermissions?: boolean;

  setPermissions: (p: ModuleAccess[]) => void;
  setLoadingPermissions: (isLoading: boolean) => void;
};

const usePermissions = create<PermissionsState>(
  persist(
    (set) => ({
      permissions: [],
      setPermissions(permissions) {
        set({ permissions });
      },
      setLoadingPermissions(isLoadingPermissions) {
        set({ isLoadingPermissions });
      },
    }),
    { name: "tracecotton/permissoes", getStorage: () => sessionStorage }
  )
);

export default usePermissions;
